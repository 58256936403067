// libraries
import Vue from 'vue';

// atoms
Vue.component('atom-animation', require('@/components/atoms/AtomAnimation.vue').default);
Vue.component('atom-avatar', require('@/components/atoms/AtomAvatar.vue').default);
Vue.component('atom-body', require('@/components/atoms/AtomBody.vue').default);
Vue.component('atom-burger', require('@/components/atoms/AtomBurger.vue').default);
Vue.component('atom-button', require('@/components/atoms/AtomButton.vue').default);
Vue.component('atom-card', require('@/components/atoms/AtomCard.vue').default);
Vue.component('atom-divider', require('@/components/atoms/AtomDivider.vue').default);
Vue.component('atom-dot', require('@/components/atoms/AtomDot.vue').default);
Vue.component('atom-heading', require('@/components/atoms/AtomHeading.vue').default);
Vue.component('atom-image', require('@/components/atoms/AtomImage.vue').default);
Vue.component('atom-label', require('@/components/atoms/AtomLabel.vue').default);
Vue.component('atom-section', require('@/components/atoms/AtomSection.vue').default);

// molecules
Vue.component('molecule-avatar-card', require('@/components/molecules/MoleculeAvatarCard.vue').default);
Vue.component('molecule-avatar-image', require('@/components/molecules/MoleculeAvatarImage.vue').default);
Vue.component('molecule-burger-button', require('@/components/molecules/MoleculeBurgerButton.vue').default);
Vue.component('molecule-card-primary', require('@/components/molecules/MoleculeCardPrimary.vue').default);
Vue.component('molecule-card-secondary', require('@/components/molecules/MoleculeCardSecondary.vue').default);
Vue.component('molecule-collapsible-card', require('@/components/molecules/MoleculeCollapsibleCard.vue').default);
Vue.component('molecule-dot-navigation', require('@/components/molecules/MoleculeDotNavigation.vue').default);
Vue.component('molecule-info-tile', require('@/components/molecules/MoleculeInfoTile.vue').default);
Vue.component('molecule-journey-cta', require('@/components/molecules/MoleculeJourneyCta.vue').default);
Vue.component('molecule-member-avatar', require('@/components/molecules/MoleculeMemberAvatar.vue').default);
Vue.component('molecule-teaser-link', require('@/components/molecules/MoleculeTeaserLink.vue').default);
Vue.component('molecule-teaser-primary', require('@/components/molecules/MoleculeTeaserPrimary.vue').default);

// organisms
Vue.component('organism-footer', require('@/components/organisms/OrganismFooter.vue').default);
Vue.component('organism-header', require('@/components/organisms/OrganismHeader.vue').default);
