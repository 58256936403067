import { Breakpoint } from './breakpoint';
import { BreakpointOptions, BreakpointNameEnum } from './types';
import { VueConstructor } from 'vue/types/vue';

export default {
  install(Vue: VueConstructor, _options: BreakpointOptions) {
    const options = {
      ...{
        mobileBreakpoint: BreakpointNameEnum.sm,
        thresholds: {
          xs: 0,
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1200,
          xxl: 1400,
        },
      },
      ..._options,
    };

    const breakpoint = new Breakpoint(options);

    Vue.prototype.$breakpoint = Vue.observable(breakpoint);
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $breakpoint: Breakpoint;
  }
}
