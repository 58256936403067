// libraries
import axios from 'axios';
import Vue from 'vue';

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API || '',
  headers: {
    Accept: 'application/json',
  },
});

api.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => Promise.reject(error),
);

export default {
  install() {
    Vue.prototype.$api = api;
  },
};
