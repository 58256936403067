




















































// libraries
import { Component, Vue } from 'vue-property-decorator';
import { TweenMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import Contact from '@/models/Contact';

// components
import PhoneInputForm from './partials/PhoneInputForm.vue';

@Component({
  components: {
    PhoneInputForm,
  },
})
export default class ContactView extends Vue {
  items = [
    {
      icon: 'mdi mdi-map-marker-outline',
      title: this.$t('views.contact.items.address.title'),
      body: this.$t('views.contact.items.address.text'),
    },
    {
      icon: 'mdi mdi-cellphone',
      title: this.$t('views.contact.items.phone.title'),
      body: this.$t('views.contact.items.phone.text'),
    },
    {
      icon: 'mdi mdi-email',
      title: this.$t('views.contact.items.mail.title'),
      body: this.$t('views.contact.items.mail.text'),
    },
  ];

  number = '';

  mounted() {
    const controller = new ScrollMagic.Controller();

    this.initItems(controller);
    this.initContactAction(controller);
  }

  initItems(controller: any) {
    const targets = (this.$refs.infoWrapper as HTMLElement).querySelectorAll('.molecule-info-tile');

    const tween = TweenMax.staggerFromTo(targets, 1, { left: -100, opacity: 0 }, { left: 0, opacity: 1 }, 0.2);

    new ScrollMagic.Scene({ triggerElement: '#app' }).setTween(tween).addTo(controller);
  }

  initContactAction(controller: any) {
    const target = this.$refs.contactAction as HTMLElement;

    const tween = TweenMax.fromTo(target, 1, { left: 100, opacity: 0 }, { left: 0, opacity: 1 });

    new ScrollMagic.Scene({ triggerElement: '#app' }).setTween(tween).addTo(controller);
  }

  sendContact(number: string) {
    window._paq.push(['trackEvent', 'Element interactions', 'Contact', 'Send phone']);
    Contact.$api()
      .store(number)
      .then(() => {
        this.$toasted.show('Ihre Anfrage wurde erfolgreich gespeichert', {
          position: 'top-center',
          duration: 2000,
          className: ['toasting'],
        });
      })
      .catch(() => {
        this.$toasted.show('Etwas ist schiefgelaufen', {
          position: 'top-center',
          duration: 3000,
          type: 'error',
        });
      });
  }
}
