
























// libraries
import { Component, Vue } from 'vue-property-decorator';
import { TweenMax } from 'gsap';
import ScrollMagic from 'scrollmagic';

// components
import InlineSvg from 'vue-inline-svg';

@Component({
  components: {
    InlineSvg,
  },
})
export default class HomePhone extends Vue {
  controller!: any;
  phoneSvg!: SVGElement;

  init() {
    if (this.$breakpoint.mdAndDown) return;

    if (this.controller) {
      this.controller.destroy();
      this.controller = null;
    }

    this.controller = new ScrollMagic.Controller();

    if (this.phoneSvg) this.initAnimation();
  }

  initAnimation() {
    const targets = this.phoneSvg.querySelectorAll('#Phone, #Content-0, #Content-1');

    const tween = TweenMax.staggerFromTo(targets, 1, { y: -450, opacity: 0 }, { y: -150, opacity: 1 }, 0.1);

    new ScrollMagic.Scene({ triggerElement: this.$el, triggerHook: 0.5 }).setTween(tween).addTo(this.controller);
  }

  setPhoneSvg(svg: SVGElement) {
    this.phoneSvg = svg;

    this.initAnimation();
  }

  mounted() {
    this.init();
    window.addEventListener('resize', this.init);
  }

  updated() {
    this.init();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.init);
  }
}
