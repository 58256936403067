










// libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';

// mixins
import TypoMixin from '@/mixins/typo-mixin';

// types
import { HeadlineEnum } from '@/mixins/typo-mixin';

@Component
export default class AtomHeading extends Mixins(TypoMixin) {
  @Prop({ type: String, default: '' }) readonly title!: string;

  @Prop({
    type: String,
    default: HeadlineEnum.h1,
    validator: (value) => {
      return [
        HeadlineEnum.h1,
        HeadlineEnum.h2,
        HeadlineEnum.h3,
        HeadlineEnum.h4,
        HeadlineEnum.h5,
        HeadlineEnum.h6,
      ].includes(value);
    },
  })
  readonly tag!: string;

  get classes() {
    return {
      ...this.typoMixinClasses,
    };
  }
}
