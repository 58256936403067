























// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component
export default class MoleculeCardPrimary extends Vue {
  @Prop({ type: Boolean, default: false }) readonly cta!: boolean;

  @Prop({ type: Boolean, default: false }) readonly equalHeight!: boolean;

  @Prop({ type: String, default: '' }) readonly href!: string;

  @Prop({ type: String, default: '' }) readonly linkText!: string;

  @Prop({ type: String, default: '' }) readonly text!: string;

  @Prop({ type: String, default: '' }) readonly title!: string;

  @Prop({ type: String, required: true }) readonly image!: string;

  @Prop({
    type: Object as PropType<{ [key: string]: string }>,
    default: () => ({}),
  })
  readonly route!: { [key: string]: string };

  get isExternal() {
    return !!(this.href.length && Object.values(this.route).length === 0);
  }

  get isInternal() {
    return !!(this.href.length === 0 && Object.values(this.route).length);
  }

  get linkProps() {
    const options: { [key: string]: any } = {
      color: 'secondary',
      icon: 'arrow-right2',
      iconColor: 'primary',
      iconPosition: 'after',
      iconSize: 'xl',
      text: 'right',
      iconAnimation: 'right',
      bold: true,
    };

    options.text = true;

    if (this.isInternal) {
      options.to = this.route;
    } else if (this.isExternal) {
      options.href = this.href;
    }

    return options;
  }

  get classes() {
    return {
      'molecule-card--primary': true,
      'molecule-card--primary--equal-height': this.equalHeight,
    };
  }

  onClickLink(event: PointerEvent) {
    this.$emit('clicked-link', event);
  }
}
