






// libraries
import { Component, Vue } from 'vue-property-decorator';

// models
import Stack from '@/models/Stack';

// components
import StackIcon from './StackIcon.vue';

@Component({
  components: {
    StackIcon,
  },
})
export default class StacksOverview extends Vue {
  get stacks() {
    return Stack.all();
  }
}
