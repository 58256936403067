





















// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MoleculeCardSecondary extends Vue {
  @Prop({ type: String, default: '' }) readonly text!: string;

  @Prop({ type: String, default: '' }) readonly title!: string;

  @Prop({ type: String, default: '' }) readonly subtitle!: string;
}
