import { Backdrop } from './backdrop';
import { BackdropOptions } from './types';
import { VueConstructor } from 'vue/types/vue';

export default {
  install(Vue: VueConstructor, _options: BackdropOptions) {
    const options = {
      ...{
        className: '',
        isAnimated: true,
        rootElement: document.body,
        duration: 0.5,
        hideOnClick: true,
      },
      ..._options,
    };

    const backdrop = new Backdrop(options);

    Vue.prototype.$backdrop = backdrop;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $backdrop: Backdrop;
  }
}
