import { render, staticRenderFns } from "./AtomDot.vue?vue&type=template&id=65e22b86&scoped=true&"
import script from "./AtomDot.vue?vue&type=script&lang=ts&"
export * from "./AtomDot.vue?vue&type=script&lang=ts&"
import style0 from "./AtomDot.vue?vue&type=style&index=0&id=65e22b86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e22b86",
  null
  
)

export default component.exports