






















// libraries
import { Component, Vue } from 'vue-property-decorator';
import Hammer from 'hammerjs';
import ScrollMagic from 'scrollmagic';

// utils
import { executeAfterTransition } from '@/utils/general';

// types
import { Routes } from '@/router/routes';

@Component
export default class MainLayout extends Vue {
  routes = [Routes.Home, Routes.Services, Routes.Credentials, Routes.Team, Routes.Career, Routes.Contact];

  controller: any = null;

  next() {
    const carouselTabs = (this.$refs.carouselTabs as Vue).$el;

    if (this.$breakpoint.mdAndDown && carouselTabs.classList.contains('active')) {
      switch (this.$route.name) {
        case Routes.Home:
          this.$router.push({ name: Routes.Services });
          break;
        case Routes.Services:
          this.$router.push({ name: Routes.Credentials });
          break;
        case Routes.Credentials:
          this.$router.push({ name: Routes.Team });
          break;
        case Routes.Team:
          this.$router.push({ name: Routes.Career });
          break;
        case Routes.Career:
          this.$router.push({ name: Routes.Contact });
          break;
      }
    }
  }

  previous() {
    const carouselTabs = (this.$refs.carouselTabs as Vue).$el;

    if (this.$breakpoint.mdAndDown && carouselTabs.classList.contains('active')) {
      switch (this.$route.name) {
        case Routes.Services:
          this.$router.push({ name: Routes.Home });
          break;
        case Routes.Credentials:
          this.$router.push({ name: Routes.Services });
          break;
        case Routes.Team:
          this.$router.push({ name: Routes.Credentials });
          break;
        case Routes.Career:
          this.$router.push({ name: Routes.Team });
          break;
        case Routes.Contact:
          this.$router.push({ name: Routes.Career });
          break;
      }
    }
  }

  mounted() {
    this.init();

    const hammer = new Hammer(this.$el as HTMLElement, {
      cssProps: {
        userSelect: 'true',
      } as any,
    });

    hammer.on('swipeleft', () => this.next());
    hammer.on('swiperight', () => this.previous());

    window.addEventListener('resize', this.init);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.init);
  }

  init() {
    if (this.controller) {
      this.controller.destroy();
      this.controller = null;
    }

    this.controller = new ScrollMagic.Controller();

    new ScrollMagic.Scene({ triggerElement: '#app', triggerHook: 0, duration: 50 })
      .setClassToggle((this.$refs.carouselTabs as Vue).$el, 'active')
      .addTo(this.controller);

    if (this.$breakpoint.xlAndUp) {
      new ScrollMagic.Scene({ triggerElement: '#app', triggerHook: 0, duration: 150 })
        .setClassToggle((this.$refs.appHeading as Vue).$el, 'active')
        .addTo(this.controller);
    } else {
      (this.$refs.appHeading as Vue).$el.classList.add('active');
    }

    this.updateDotNavigation();
  }

  updateDotNavigation() {
    if (this.$refs.dotNavigation && this.$route.name) {
      (this.$refs.dotNavigation as any).setActiveItemId(this.$route.name);
    }
  }

  updateAppHeading() {
    const appHeading = (this.$refs.appHeading as Vue).$el as HTMLElement;

    appHeading.style.transition = 'none';
    appHeading.classList.remove('fade', 'fade-in--right', 'active');
    appHeading.classList.add('fade', 'fade-in--right');

    const complete = () => {
      appHeading.style.transition = '';
      appHeading.classList.add('active');
    };

    executeAfterTransition(complete, appHeading, true);
  }

  onChangeTab(route: Routes) {
    this.$router.push({ name: route });
  }

  updated() {
    this.updateAppHeading();
    this.updateDotNavigation();
  }
}
