














import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PhoneInput extends Vue {
  phone = '';

  send() {
    this.$emit('send', this.phone);
    this.phone = '';
  }
}
