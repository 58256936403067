








































































































































































































































































































































































































































































































































































// libraries
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LawView extends Vue {}
