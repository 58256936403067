enum GeneralEnum {
  Transitionend = 'transitionend',
  Multiplier = 1000,
}

const triggerTransitionEnd = (element: HTMLElement) => {
  element.dispatchEvent(new Event(GeneralEnum.Transitionend));
};

const getTransitionDurationFromElement = (element: HTMLElement) => {
  if (!element) {
    return 0;
  }

  let { transitionDuration, transitionDelay } = window.getComputedStyle(element);

  const floatTransitionDuration = Number.parseFloat(transitionDuration);
  const floatTransitionDelay = Number.parseFloat(transitionDelay);

  if (!floatTransitionDuration && !floatTransitionDelay) {
    return 0;
  }

  transitionDuration = transitionDuration.split(',')[0];
  transitionDelay = transitionDelay.split(',')[0];

  return (Number.parseFloat(transitionDuration) + Number.parseFloat(transitionDelay)) * GeneralEnum.Multiplier;
};

const execute = (callback: () => void) => {
  if (typeof callback === 'function') callback();
};

const executeAfterTransition = (callback: () => void, transitionElement: HTMLElement, waitForTransition = true) => {
  if (!waitForTransition) {
    execute(callback);

    return;
  }

  const durationPadding = 5;
  const emulatedDuration = getTransitionDurationFromElement(transitionElement) + durationPadding;

  let called = false;

  const handler = ({ target }: TransitionEvent) => {
    if (target !== transitionElement) return;

    called = true;
    transitionElement.removeEventListener(GeneralEnum.Transitionend, handler);

    execute(callback);
  };

  transitionElement.addEventListener(GeneralEnum.Transitionend, handler);

  setTimeout(() => {
    if (!called) triggerTransitionEnd(transitionElement);
  }, emulatedDuration);
};

const truncate = (str: string, length: number) => {
  return str.substring(0, length) + (str.length > length ? '...' : '');
};

const reflow = (element: HTMLElement) => element.offsetHeight;

const numberToPx = (number: number) => `${number}px`;

const isElement = (obj: any) => {
  if (!obj || typeof obj !== 'object') return false;

  if (typeof obj.jquery !== 'undefined') obj = obj[0];

  return typeof obj.nodeType !== 'undefined';
};

const getElement = (obj: any) => {
  if (isElement(obj)) return obj.jquery ? obj[0] : obj;

  if (typeof obj === 'string' && obj.length > 0) return document.querySelector(obj);

  return null;
};

export {
  execute,
  executeAfterTransition,
  getTransitionDurationFromElement,
  numberToPx,
  reflow,
  triggerTransitionEnd,
  truncate,
  isElement,
  getElement,
};
