import Vue, { VNodeDirective } from 'vue';
import { executeAfterTransition, numberToPx, reflow, getElement } from '@/utils/general';

export enum ClassEnum {
  Collapse = 'pssg-collapse',
  Collapsing = 'pssg-collapse--collapsing',
  Open = 'pssg-collapse--open',
}

export enum EventEnum {
  Click = 'click',
  HideTarget = 'hideTarget',
  OpenTarget = 'openTarget',
  Resize = 'resize',
}

const isOpen = (target: HTMLElement) => {
  return target.classList.contains(ClassEnum.Open);
};

const isCollapsing = (target: HTMLElement) => {
  return target.classList.contains(ClassEnum.Collapsing);
};

const openTarget = (binding: VNodeDirective) => {
  const target = getElement(binding.value.target);

  if (!target) return;

  if (isCollapsing(target)) return;

  target.classList.remove(ClassEnum.Collapse);
  target.classList.add(ClassEnum.Collapsing);

  target.style.height = numberToPx(0);

  const complete = () => {
    target.classList.remove(ClassEnum.Collapsing);
    target.classList.add(ClassEnum.Collapse, ClassEnum.Open);
  };

  executeAfterTransition(complete, target, true);

  target.style.height = numberToPx(target.scrollHeight);
};

const hideTarget = (binding: VNodeDirective) => {
  const target = getElement(binding.value.target);

  if (!target) return;

  if (isCollapsing(target)) return;

  target.style.height = numberToPx(target.getBoundingClientRect().height);

  reflow(target);

  target.classList.add(ClassEnum.Collapsing);
  target.classList.remove(ClassEnum.Collapse, ClassEnum.Open);

  const complete = () => {
    target.classList.remove(ClassEnum.Collapsing);
    target.classList.add(ClassEnum.Collapse);
  };

  target.style.height = '';

  executeAfterTransition(complete, target, true);
};

const toggle = (binding: VNodeDirective) => {
  const target = getElement(binding.value.target);

  if (!target) return;

  isOpen(target) ? hideTarget(binding) : openTarget(binding);
};

const onResize = (binding: VNodeDirective) => {
  const target = getElement(binding.value.target);

  if (!target) return;

  hideTarget(binding);
};

Vue.directive('collapse', {
  inserted: (_element, binding) => {
    const target = getElement(binding.value.target);

    if (target) target.classList.add(ClassEnum.Collapse);
  },
  bind: (element, binding: VNodeDirective) => {
    const target = getElement(binding.value.target);

    if (!binding.value.preventClick) {
      element.addEventListener(EventEnum.Click, () => toggle(binding));
    }

    element.addEventListener(EventEnum.OpenTarget, () => openTarget(binding));
    element.addEventListener(EventEnum.HideTarget, () => hideTarget(binding));

    if (target) window.addEventListener(EventEnum.Resize, () => onResize(binding));
  },
  unbind: (element, binding: VNodeDirective) => {
    const target = getElement(binding.value.target);

    if (target) target.classList.remove(ClassEnum.Collapse);

    if (!binding.value.preventClick) {
      element.removeEventListener(EventEnum.Click, () => toggle);
    }

    element.removeEventListener(EventEnum.OpenTarget, () => openTarget);
    element.removeEventListener(EventEnum.HideTarget, () => hideTarget);

    if (target) window.removeEventListener(EventEnum.Resize, () => onResize);
  },
});
