



















// libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';

// mixins
import IconMixin from '@/mixins/icon-mixin';

// types
enum TypeEnum {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

enum ColorEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Light = 'light',
}

enum SizeEnum {
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

@Component
export default class AtomButton extends Mixins(IconMixin) {
  @Prop({ type: Boolean, default: false }) readonly block!: boolean;

  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false }) readonly uppercase!: boolean;

  @Prop({ type: Boolean, default: false }) readonly text!: boolean;

  @Prop({ type: Boolean, default: false }) readonly rounded!: boolean;

  @Prop({ type: Boolean, default: false }) readonly outlined!: boolean;

  @Prop({ type: Boolean, default: false }) readonly bold!: boolean;

  @Prop({ type: Boolean, default: false }) readonly fab!: boolean;

  @Prop({ type: String, default: '' }) readonly href!: string;

  @Prop({ type: String, default: '' }) declare readonly icon: string;

  @Prop({ type: String, default: '' }) readonly target!: string;

  @Prop({ type: [String, Object], default: '' }) readonly to!: any;

  @Prop({
    type: String,
    default: ColorEnum.Primary,
    validator: (value: ColorEnum) => [ColorEnum.Primary, ColorEnum.Secondary, ColorEnum.Light].includes(value),
  })
  readonly color!: string;

  @Prop({
    type: String,
    default: TypeEnum.Button,
    validator: (value: TypeEnum) => [TypeEnum.Button, TypeEnum.Reset, TypeEnum.Submit].includes(value),
  })
  readonly type!: string;

  @Prop({
    type: String,
    default: SizeEnum.md,
    validator: (value) => [SizeEnum.md, SizeEnum.lg, SizeEnum.xl].includes(value), // TODO
  })
  readonly size!: SizeEnum;

  get tag() {
    if (this.href) return 'a';
    if (this.to) return 'router-link';

    return 'button';
  }

  get classes() {
    return {
      ...(this.icon ? this.iconMixinClasses : {}),
      ...{
        'atom-button--block': this.block,
        'atom-button--bold': this.bold,
        'atom-button--disabled': this.disabled,
        'atom-button--fab': this.fab,
        'atom-button--outlined': this.outlined,
        'atom-button--rounded': this.rounded,
        'atom-button--text': this.text,
        'atom-button--uppercase': this.uppercase,
        'atom-button': true,
        [`atom-button--${this.color}`]: !!this.color,
        [`atom-button--${this.size}`]: !!this.size,
        [this.icon as string]: this.icon,
      },
    };
  }

  get computedTarget() {
    return this.target || (this.href ? '_blank' : undefined);
  }

  onClick(event: MouseEvent | TouchEvent) {
    if (this.disabled) event.preventDefault();
  }
}
