// types
import {
  BreakpointNameEnum,
  BreakpointOptions,
  BreakpointThresholds,
  Breakpoint as BreakpointInterface,
} from './types';

export class Breakpoint implements BreakpointInterface {
  xs = false;
  sm = false;
  md = false;
  lg = false;
  xl = false;
  xxl = false;

  xsOnly = false;
  xsAndUp = false;

  smOnly = false;
  smAndDown = false;
  smAndUp = false;

  mdOnly = false;
  mdAndDown = false;
  mdAndUp = false;

  lgOnly = false;
  lgAndDown = false;
  lgAndUp = false;

  xlOnly = false;
  xlAndDown = false;
  xlAndUp = false;

  xxlOnly = false;
  xxlAndDown = false;

  name: BreakpointInterface['name'] = BreakpointNameEnum.xs;
  mobileBreakpoint: BreakpointInterface['mobileBreakpoint'];
  thresholds: BreakpointInterface['thresholds'];
  height = 0;
  width = 0;
  mobile = true;

  constructor(options: BreakpointOptions) {
    const { mobileBreakpoint, thresholds } = options;

    this.mobileBreakpoint = mobileBreakpoint as number | BreakpointNameEnum;
    this.thresholds = thresholds as BreakpointThresholds;

    _init(this);
  }

  update() {
    const height = this.getClientHeight();
    const width = this.getClientWidth();

    const xs = width < this.thresholds.sm;
    const sm = width < this.thresholds.md && !xs;
    const md = width < this.thresholds.lg && !(xs || sm);
    const lg = width < this.thresholds.xl && !(xs || sm || md);
    const xl = width < this.thresholds.xxl && !(xs || sm || md || lg);
    const xxl = width >= this.thresholds.xxl;

    this.height = height;
    this.width = width;

    this.xs = xs;
    this.sm = sm;
    this.md = md;
    this.lg = lg;
    this.xl = xl;
    this.xxl = xxl;

    this.xsOnly = xs;

    this.smOnly = sm;
    this.smAndDown = (xs || sm) && !(md || lg || xl || xxl);
    this.smAndUp = !xs && (sm || md || lg || xl || xxl);

    this.mdOnly = md;
    this.mdAndDown = (xs || sm || md) && !(lg || xl || xxl);
    this.mdAndUp = !(xs || sm) && (md || lg || xl || xxl);

    this.lgOnly = lg;
    this.lgAndDown = (xs || sm || md || lg) && !(xl || xxl);
    this.lgAndUp = !(xs || sm || md) && (lg || xl || xxl);

    this.xlOnly = xl;
    this.xlAndDown = (xs || sm || md || lg || xl) && !xxl;
    this.xlAndUp = !(xs || sm || md || lg) && (xl || xxl);

    this.xxlOnly = xxl;

    switch (true) {
      case xs:
        this.name = BreakpointNameEnum.xs;
        break;
      case sm:
        this.name = BreakpointNameEnum.sm;
        break;
      case md:
        this.name = BreakpointNameEnum.md;
        break;
      case lg:
        this.name = BreakpointNameEnum.lg;
        break;
      case xl:
        this.name = BreakpointNameEnum.xl;
        break;
      default:
        this.name = BreakpointNameEnum.xxl;
        break;
    }

    if (typeof this.mobileBreakpoint === 'number') {
      this.mobile = width < this.mobileBreakpoint;

      return;
    }

    const breakpoints = {
      xs: 0,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 4,
      xxl: 5,
    } as const;

    const current = breakpoints[this.name];
    const max = breakpoints[this.mobileBreakpoint];

    this.mobile = current <= max;
  }

  getClientWidth() {
    if (typeof document === 'undefined') return 0;

    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }

  getClientHeight() {
    if (typeof document === 'undefined') return 0;

    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }
}

const _init = (instance: Breakpoint) => {
  instance.update();

  if (typeof window === 'undefined') return;

  window.addEventListener('resize', () => instance.update());
};
