






// libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';

// mixins
import ShadowMixin from '@/mixins/shadow-mixin';

@Component
export default class MoleculeAvatarImage extends Mixins(ShadowMixin) {
  @Prop({ type: [Number, String], required: false }) readonly imageSize!: number | string;

  @Prop({ type: Boolean, default: false }) readonly fab!: boolean;

  @Prop({ type: Boolean, default: false }) readonly rounded!: boolean;

  @Prop({ type: Number, default: 0 }) readonly elevation!: number;

  @Prop({ type: String, required: false }) readonly imageClass?: string;

  @Prop({ type: String, required: true }) readonly image!: string;

  @Prop({
    type: String,
    default: '56px',
    validator: (value) => value.includes('%') || value.includes('px') || value.includes('em') || value.includes('rem'),
  })
  readonly size!: string;

  get styles() {
    return {
      ...this.shadowMixinStyles,
    };
  }
}
