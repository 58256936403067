










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AtomLabel extends Vue {
  @Prop({ type: String, default: '' }) readonly text!: string;
}
