


































































// libraries
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ScrollMagic from 'scrollmagic';

// types
import { Routes } from '@/router/routes';

// utils
import { truncate } from '@/utils/general';

// components
import StacksOverview from './partials/StacksOverview.vue';

@Component({
  components: {
    StacksOverview,
  },
})
export default class ServicesView extends Vue {
  credentialsRoute = Routes.Credentials;

  controller!: any;

  services = [
    {
      image: require('@/assets/images/consulting.svg'),
      title: this.$t('views.services.items.consulting.title'),
      text: this.$t('views.services.items.consulting.text'),
      longtext: this.$t('views.services.items.consulting.longtext'),
    },
    {
      image: require('@/assets/images/monitors.svg'),
      title: this.$t('views.services.items.frontend.title'),
      text: this.$t('views.services.items.frontend.text'),
      longtext: this.$t('views.services.items.frontend.longtext'),
    },
    {
      image: require('@/assets/images/monitor.svg'),
      title: this.$t('views.services.items.backend.title'),
      text: this.$t('views.services.items.backend.text'),
      longtext: this.$t('views.services.items.backend.longtext'),
    },
    {
      image: require('@/assets/images/smartphone-person.svg'),
      title: this.$t('views.services.items.apps.title'),
      text: this.$t('views.services.items.apps.text'),
      longtext: this.$t('views.services.items.apps.longtext'),
    },
    {
      image: require('@/assets/images/concepting.svg'),
      title: this.$t('views.services.items.management.title'),
      text: this.$t('views.services.items.management.text'),
      longtext: this.$t('views.services.items.management.longtext'),
    },
    {
      image: require('@/assets/images/service-wartung.svg'),
      title: this.$t('views.services.items.servicewartungen.title'),
      text: this.$t('views.services.items.servicewartungen.text'),
      longtext: this.$t('views.services.items.servicewartungen.longtext'),
    },
    {
      image: require('@/assets/images/stacks.svg'),
      title: this.$t('views.services.items.stacks.title'),
      text: this.$t('views.services.items.stacks.text'),
      longtext: this.$t('views.services.items.stacks.longtext'),
    },
  ];

  mounted() {
    this.init();

    window.addEventListener('resize', this.init);
  }

  updated() {
    this.init();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.init);
  }

  init() {
    if (this.controller) {
      this.controller.destroy();
      this.controller = null;
    }

    this.controller = new ScrollMagic.Controller();

    this.initCards();
    this.initJourneyCta();
    this.initTeasers();
  }

  initCards() {
    const cards = _.values(_.pickBy(this.$refs, (_value, key) => key.includes('serviceCard')));

    cards.forEach(([triggerElement]: any) => {
      const element = triggerElement.querySelector('div');
      const boundingClientRect = element.getBoundingClientRect();

      element.classList.remove('active');

      if (this.$breakpoint.mdAndDown) {
        element.classList.add('active');

        return;
      }

      new ScrollMagic.Scene({ triggerElement, triggerHook: 0.5, duration: '100%' })
        .setClassToggle(element, 'active')
        .on('leave', function (event: any) {
          if (event.scrollDirection === 'REVERSE' && boundingClientRect.top < window.innerHeight) {
            element.classList.add('active');
          }
        })
        .addTo(this.controller);

      if (boundingClientRect.top < window.innerHeight) {
        element.classList.add('active');
      }
    });
  }

  initJourneyCta() {
    const cta = (this.$refs.journeyCta as Vue).$el;

    cta.classList.remove('active');

    if (this.$breakpoint.mdAndDown) {
      cta.classList.add('active');

      return;
    }

    new ScrollMagic.Scene({ triggerElement: cta, triggerHook: 0.9, duration: '100%' })
      .setClassToggle(cta, 'active')
      .addTo(this.controller);
  }

  initTeasers() {
    const teasers = _.values(_.pickBy(this.$refs, (_value, key) => key.includes('serviceTeaser')));

    teasers.forEach((teaser: any) => {
      const element = teaser[0].$el as HTMLElement;

      element.classList.remove('active');

      if (this.$breakpoint.mdAndDown) {
        element.classList.add('active');

        return;
      }

      new ScrollMagic.Scene({ triggerElement: element, triggerHook: 0.8, duration: '100%' })
        .setClassToggle(element, 'active')
        .addTo(this.controller);
    });
  }

  getCardClasses(index: number) {
    let marginClass = '';

    if (this.$breakpoint.lgAndUp) {
      if (index % 3 === 0) {
        marginClass = 'me-auto';
      } else if ((index + 1) % 3 === 0) {
        marginClass = 'ms-auto';
      } else {
        marginClass = 'mx-auto';
      }
    }

    return {
      'service-tile-wrapper h-100 fade fade-in--up': true,
      [marginClass]: !!marginClass,
    };
  }

  getTeaserClasses(index: number) {
    const fadeClass = index % 2 === 0 ? 'fade-in--left' : 'fade-in--right';

    return {
      'mb-5 fade': true,
      [fadeClass]: true,
    };
  }

  getTeaserOrder(index: number) {
    if (this.$breakpoint.lgAndUp) return index % 2 === 0;

    return true;
  }

  getTruncatedCardText(str: string) {
    return truncate(str, 142);
  }

  onClickCardLink(_event: PointerEvent, index: number) {
    const element = (this.$refs[`serviceTeaser-${index}`] as any)[0].$el;

    this.$scrollTo(element);
  }

  @Watch('$route')
  onChangeRoute() {
    this.init();
  }
}
