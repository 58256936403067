




























































// libraries
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ScrollMagic from 'scrollmagic';

// utils
import { truncate } from '@/utils/general';

// models
import Credential from '@/models/Credential';

// types
import { Routes } from '@/router/routes';

@Component
export default class CredentialsView extends Vue {
  teamRoute = Routes.Team;

  controller!: any;

  mounted() {
    this.init();

    window.addEventListener('resize', this.init);
  }

  updated() {
    this.init();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.init);
  }

  get credentials() {
    const credentials = Credential.all();

    const conclusion = new Credential({ text: 'Start your own project now.' });

    credentials.push(conclusion);

    return credentials;
  }

  init() {
    if (this.controller) {
      this.controller.destroy();
      this.controller = null;
    }

    this.controller = new ScrollMagic.Controller();

    this.initCards();
  }

  initCards() {
    const cards = _.values(_.pickBy(this.$refs, (_value, key) => key.includes('credentialCard')));

    cards.forEach(([triggerElement]: any) => {
      const element = triggerElement.querySelector('div');
      const boundingClientRect = element.getBoundingClientRect();

      element.classList.remove('active');

      if (this.$breakpoint.mdAndDown) {
        element.classList.add('active');

        return;
      }

      new ScrollMagic.Scene({ triggerElement, triggerHook: 0.8, duration: '100%' })
        .setClassToggle(element, 'active')
        .on('leave', function (event: any) {
          if (event.scrollDirection === 'REVERSE' && boundingClientRect.top < window.innerHeight) {
            element.classList.add('active');
          }
        })
        .addTo(this.controller);

      if (boundingClientRect.top < window.innerHeight) {
        element.classList.add('active');
      }
    });
  }

  getTruncatedCardText(str: string) {
    if (this.$breakpoint.xlAndDown) {
      return truncate(str, 100);
    }

    if (this.$breakpoint.xlAndUp) {
      return truncate(str, 65);
    }
  }

  @Watch('$route')
  onChangeRoute() {
    this.init();
  }
}
