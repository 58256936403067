






// libraries
import { Component, Mixins } from 'vue-property-decorator';

// mixins
import ElevationMixin from '@/mixins/elevation-mixin';
import ShadowMixin from '@/mixins/shadow-mixin';

@Component
export default class AtomCard extends Mixins(ElevationMixin, ShadowMixin) {
  get classes() {
    return {
      'atom-card': true,
      ...this.elevationMixinClasses,
    };
  }

  get styles() {
    return {
      ...this.shadowMixinStyles,
    };
  }
}
