




// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// types
enum DirectionEnum {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

@Component
export default class AtomDivider extends Vue {
  @Prop({
    type: String,
    default: DirectionEnum.horizontal,
    validator: (value) => [DirectionEnum.horizontal, DirectionEnum.vertical].includes(value),
  })
  readonly direction!: DirectionEnum;

  get classes() {
    return {
      'atom-divider': true,
      [this.direction]: true,
    };
  }
}
