export enum DirectionEnum {
  Top = 'top',
  Bottom = 'bottom',
}

export default class ScrollHandler {
  scrollYOld = window.pageYOffset;
  scrollYNew = window.pageYOffset;
  directionOld: DirectionEnum | undefined = undefined;

  get direction() {
    const difference = this.scrollYOld - this.scrollYNew;

    let direction = undefined;

    if (difference < 0) {
      direction = DirectionEnum.Bottom;
    } else if (difference > 0) {
      direction = DirectionEnum.Top;
    }

    _update(this, direction);

    if (_isVerticalDirection(direction)) return direction;

    return this.directionOld;
  }

  get isScrollTop() {
    return this.direction === DirectionEnum.Top;
  }

  get isScrollBottom() {
    return this.direction === DirectionEnum.Bottom;
  }
}

const _isVerticalDirection = (direction: DirectionEnum | undefined) => {
  if (!direction) return;

  return [DirectionEnum.Bottom, DirectionEnum.Top].includes(direction);
};

const _update = (instance: ScrollHandler, direction: DirectionEnum | undefined) => {
  instance.scrollYOld = instance.scrollYNew;
  instance.scrollYNew = window.pageYOffset;

  if (!direction) return;

  if (_isVerticalDirection(direction)) instance.directionOld = direction;
};
