






































// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// models
import Review from '@/models/Review';

// styles
import 'swiper/css/swiper.css';

@Component
export default class HomeReviews extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly reviews!: Review[];

  swiperOptions = {
    spaceBetween: 30,
    slidesPerView: 1,

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      el: '.swiper-pagination',
      nextEl: '.album-slider__button-next',
      prevEl: '.album-slider__button-prev',
    },
  };
}
