import { BackdropOptions } from './types';
import { executeAfterTransition, reflow } from '@/utils/general';
import BackdropComponent from '@/components/atoms/AtomBackdrop.vue';
import Vue from 'vue';

enum ClassNameEnum {
  Element = 'pssg-backdrop',
  Fade = 'pssg-backdrop--fade',
  Show = 'pssg-backdrop--show',
}

enum MouseEventEnum {
  Mousedown = 'mousedown',
}

export class Backdrop {
  element?: Element;
  isAppended: boolean;
  options: BackdropOptions;

  constructor(options: BackdropOptions) {
    this.element = undefined;
    this.isAppended = false;
    this.options = options;
  }

  show() {
    if (this.isAppended) return;

    _append(this);

    if (this.options.isAnimated) reflow(_getElement(this));

    _getElement(this).classList.add(ClassNameEnum.Show);
  }

  hide() {
    _getElement(this).classList.remove(ClassNameEnum.Show);

    _emulateAnimation(this, () => _dispose(this));
  }
}

const _getElement = (instance: Backdrop): HTMLElement => {
  if (!instance.element) {
    const BackdropComponentInstance = Vue.extend(BackdropComponent);

    const component = new BackdropComponentInstance({}).$mount();

    const element = component.$el as HTMLElement;

    if (instance.options.className) {
      element.classList.add(instance.options.className);
    }

    if (instance.options.isAnimated) {
      element.classList.add(ClassNameEnum.Fade);
      element.style.transition = `opacity ${instance.options.duration}s`;
    }

    instance.element = element;
  }

  return instance.element as HTMLElement;
};

const _append = (instance: Backdrop) => {
  if (instance.isAppended) return;

  instance.options.rootElement.appendChild(_getElement(instance));

  instance.isAppended = true;

  if (instance.options.hideOnClick && instance.element) {
    instance.element.addEventListener(MouseEventEnum.Mousedown, () => instance.hide());
  }
};

const _dispose = (instance: Backdrop) => {
  if (!instance.isAppended) return;

  instance.isAppended = false;

  if (instance.options.hideOnClick && instance.element) {
    instance.element.remove();
    instance.element.removeEventListener(MouseEventEnum.Mousedown, () => instance.hide());
    instance.element = undefined;
  }
};

const _emulateAnimation = (instance: Backdrop, callback: () => void) => {
  executeAfterTransition(callback, _getElement(instance), instance.options.isAnimated);
};
