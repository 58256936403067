




// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AtomDot extends Vue {
  @Prop({ type: Boolean, default: false }) readonly active!: boolean;

  @Prop({ type: [Number, String], default: 16 }) readonly diameter!: number | string;

  get classes() {
    return {
      active: this.active,
    };
  }

  get styles() {
    return {
      height: `${this.diameter}px`,
      width: `${this.diameter}px`,
    };
  }
}
