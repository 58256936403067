






































// libraries
import { Component, Vue } from 'vue-property-decorator';

// models
import Review from '@/models/Review';

// components
import HomeIntro from './partials/HomeIntro.vue';
import HomePhone from './partials/HomePhone.vue';
import HomeYourPartner from './partials/HomeYourPartner.vue';
import HomeServices from './partials/HomeServices.vue';
import HomeReviews from './partials/HomeReviews.vue';
import HomeOutro from './partials/HomeOutro.vue';

@Component({
  components: {
    HomeIntro,
    HomePhone,
    HomeYourPartner,
    HomeServices,
    HomeReviews,
    HomeOutro,
  },
})
export default class HomeView extends Vue {
  get reviews() {
    return Review.all();
  }
}
