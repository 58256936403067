





















// libraries
import { Component, Vue } from 'vue-property-decorator';

// types
import { Routes } from '@/router/routes';

@Component
export default class OrganismFooter extends Vue {
  items = [
    // {
    //   title: this.$t('footer.agb'),
    //   route: Routes.Agb,
    // },
    {
      title: this.$t('footer.legal'),
      route: Routes.Legal,
    },
  ];

  background = require('@/assets/images/footer.svg');

  get year() {
    return new Date().getFullYear();
  }
}
