





























// libraries
import { Component, Vue } from 'vue-property-decorator';

// models
import Credential from '@/models/Credential';
import JobAdvertisement from '@/models/JobAdvertisement';
import Member from '@/models/Member';
import Stack from '@/models/Stack';
import Review from '@/models/Review';

// utils
import ScrollHandler from '@/utils/scroll';

@Component
export default class App extends Vue {
  scrollHandler = new ScrollHandler();

  locationSrc = require('@/assets/images/location.svg');

  created() {
    Credential.$api().index();
    JobAdvertisement.$api().index();
    Member.$api().index();
    Stack.$api().index();
    Review.$api().index();
  }

  mounted() {
    this.updateTopCta(false);

    window.addEventListener('scroll', this.onScroll);
  }

  beforeDestory() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    if (this.scrollHandler.isScrollBottom) {
      this.updateTopCta(true);
    } else if (this.scrollHandler.isScrollTop) {
      this.updateTopCta(false);
    }
  }

  updateTopCta(status: boolean) {
    const toTopCta = this.$el.querySelector('#to-top') as HTMLElement;

    if (status) {
      toTopCta.style.bottom = '150px';
      toTopCta.style.opacity = '1';
    } else {
      toTopCta.style.bottom = '0';
      toTopCta.style.opacity = '0';
    }
  }
}
