import { Model } from '@vuex-orm/core';
import Vue from 'vue';

export default class Credential extends Model {
  static entity = 'credential';

  static fields() {
    return {
      image: this.string(''),
      name: this.string(''),
      text: this.string(''),
      order: this.number(''),
    };
  }

  static $api() {
    return {
      async index() {
        const url = `${Vue.prototype.$api.defaults.baseURL}/credentials`;

        return await Credential.api().get(url);
      },
    };
  }
}
