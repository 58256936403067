// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// enums
enum IconColorEnum {
  primary = 'primary',
  secondary = 'secondary',
  light = 'light',
}

enum IconPositionEnum {
  before = 'before',
  after = 'after',
  none = 'none',
}

export enum IconAnimationEnum {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

export enum IconSizeEnum {
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

@Component
export default class IconMixin extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  readonly icon!: string;

  @Prop({
    type: String,
    default: '',
    validator: (value) => {
      return ['', IconColorEnum.primary, IconColorEnum.secondary, IconColorEnum.light].includes(value);
    },
  })
  readonly iconColor!: IconColorEnum;

  @Prop({
    type: String,
    default: IconPositionEnum.after,
    validator: (value) => {
      return [IconPositionEnum.before, IconPositionEnum.after, IconPositionEnum.none].includes(value);
    },
  })
  readonly iconPosition!: IconPositionEnum;

  @Prop({
    type: String,
    default: IconSizeEnum.md,
    validator: (value) => {
      return [IconSizeEnum.md, IconSizeEnum.lg, IconSizeEnum.xl].includes(value);
    },
  })
  readonly iconSize!: IconSizeEnum;

  @Prop({
    type: String,
    validator: (value) => {
      return [
        IconAnimationEnum.top,
        IconAnimationEnum.right,
        IconAnimationEnum.bottom,
        IconAnimationEnum.left,
      ].includes(value);
    },
  })
  readonly iconAnimation!: IconAnimationEnum;

  get iconMixinClasses() {
    let icon = '';

    if (this.icon.includes('mdi mdi-') || this.icon.includes('fa fa-')) {
      icon = this.icon;
    } else {
      icon = `pssg-icon--default pssg-icon--${this.icon}`;
    }

    const position = this.iconPosition === IconPositionEnum.before ? IconPositionEnum.before : IconPositionEnum.after;

    return {
      [`pssg-icon ${icon}`]: !!this.icon,
      [`pssg-icon--${this.iconColor}`]: !!this.iconColor,
      [`pssg-icon--${this.iconPosition}`]: position,
      [`pssg-icon--${this.iconSize}`]: !!this.iconSize,
      [`pssg-icon-animation pssg-icon-animation--${this.iconAnimation}`]: !!this.iconAnimation,
    };
  }
}
