export enum Routes {
  Agb = 'agb',
  Career = 'career',
  Contact = 'contact',
  Credentials = 'credentials',
  Home = 'home',
  Legal = 'legal',
  Services = 'services',
  Team = 'team',
  Error = 'error',
}
