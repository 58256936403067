import { render, staticRenderFns } from "./MoleculeCardSecondary.vue?vue&type=template&id=3b80bedc&scoped=true&"
import script from "./MoleculeCardSecondary.vue?vue&type=script&lang=ts&"
export * from "./MoleculeCardSecondary.vue?vue&type=script&lang=ts&"
import style0 from "./MoleculeCardSecondary.vue?vue&type=style&index=0&id=3b80bedc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b80bedc",
  null
  
)

export default component.exports