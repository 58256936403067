// types
export enum BreakpointNameEnum {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

// interfaces
export interface BreakpointOptions {
  mobileBreakpoint?: number | BreakpointNameEnum;
  thresholds?: BreakpointThresholds;
}

export interface BreakpointThresholds {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

export declare class Breakpoint {
  constructor(options?: BreakpointOptions);

  name: BreakpointNameEnum;

  height: number;
  width: number;

  mobile: boolean;
  mobileBreakpoint: number | BreakpointNameEnum;
  thresholds: BreakpointThresholds;

  xs: boolean;
  xsOnly: boolean;

  sm: boolean;
  smAndDown: boolean;
  smAndUp: boolean;
  smOnly: boolean;

  lg: boolean;
  lgAndDown: boolean;
  lgAndUp: boolean;
  lgOnly: boolean;

  md: boolean;
  mdAndDown: boolean;
  mdAndUp: boolean;
  mdOnly: boolean;

  xl: boolean;
  xlAndDown: boolean;
  xlAndUp: boolean;
  xlOnly: boolean;

  xxl: boolean;
  xxlOnly: boolean;

  update(): void;
  getClientWidth(): number;
  getClientHeight(): number;
}
