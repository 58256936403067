






// libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';

// mixins
import ElevationMixin from '@/mixins/elevation-mixin';

@Component
export default class AtomAvatar extends Mixins(ElevationMixin) {
  @Prop({ type: Boolean, default: false }) readonly tile!: boolean;

  @Prop({ type: Boolean, default: false }) readonly rounded!: boolean;

  @Prop({ type: Boolean, default: false }) readonly fab!: boolean;

  @Prop({
    type: String,
    validator: (value) => value.includes('%') || value.includes('px') || value.includes('em') || value.includes('rem'),
  })
  readonly size!: string;

  get classes() {
    return {
      'atom-avatar': true,
      ['atom-avatar--rounded']: this.rounded,
      ['atom-avatar--fab']: this.fab,
      ['atom-avatar--tile']: this.tile,
      ...this.elevationMixinClasses,
    };
  }

  get styles() {
    return {
      height: this.size ?? this.size,
      width: this.size ?? this.size,
    };
  }
}
