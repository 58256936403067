



















// libraries
import { Component, Vue } from 'vue-property-decorator';

// types
import { Routes } from '@/router/routes';

@Component
export default class HomeServices extends Vue {
  services = [
    {
      imageDefault: require('@/assets/images/pair-credentials-default.svg'),
      imageActive: require('@/assets/images/pair-credentials-active.svg'),
      text: this.$t('views.home.links.credentials'),
      route: { name: Routes.Credentials },
    },
    {
      imageDefault: require('@/assets/images/pair-services-default.svg'),
      imageActive: require('@/assets/images/pair-services-active.svg'),
      text: this.$t('views.home.links.services'),
      route: { name: Routes.Services },
    },
    {
      imageDefault: require('@/assets/images/pair-team-default.svg'),
      imageActive: require('@/assets/images/pair-team-active.svg'),
      text: this.$t('views.home.links.team'),
      route: { name: Routes.Team },
    },
    {
      imageDefault: require('@/assets/images/pair-career-default.svg'),
      imageActive: require('@/assets/images/pair-career-active.svg'),
      text: this.$t('views.home.links.career'),
      route: { name: Routes.Career },
    },
  ];
}
