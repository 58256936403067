














// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MoleculeDotNavigation extends Vue {
  @Prop({ type: String, default: '' }) readonly defaultId!: string;

  @Prop({ type: Array, default: () => [] }) readonly items!: string[];

  activeItemId = '';

  sliderLeft: number | string = 0;

  mounted() {
    if (this.defaultId) {
      this.activeItemId = this.defaultId;
    } else if (this.items.length) {
      this.activeItemId = this.items[0];
    }

    window.addEventListener('resize', this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  get sliderStyles() {
    return {
      position: 'absolute',
      left: this.sliderLeft,
    };
  }

  onResize() {
    this.sliderLeft = this.getSliderLeft();
  }

  getSliderLeft() {
    const el = this.$el as HTMLElement;
    const dot = this.$refs.sliderDot as HTMLElement;

    const index = this.items.findIndex((item: string) => item === this.activeItemId);

    if (el && dot) {
      return `${(((index * (el.clientWidth - dot.clientWidth)) / el.clientWidth) * 100) / (this.items.length - 1)}%`;
    }

    return 0;
  }

  setActiveItemId(id: string, emit = false) {
    this.activeItemId = id;

    this.sliderLeft = this.getSliderLeft();

    if (emit) {
      this.$emit('change', id);
    }
  }
}
