
























// libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';
import { Route } from 'vue-router';

// mixins
import ShadowMixin from '@/mixins/shadow-mixin';

@Component
export default class MoleculeJourneyCta extends Mixins(ShadowMixin) {
  @Prop({ type: String, required: true }) readonly text!: string;

  @Prop({ type: String, default: 'xl' }) declare readonly size: string;

  @Prop({
    type: Object as PropType<Route>,
    required: true,
    validator: (route: Route) => !!route.name,
  })
  readonly route!: Route;

  get styles() {
    return {
      ...this.shadowMixinStyles,
    };
  }
}
