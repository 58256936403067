






import ScrollMagic from 'scrollmagic';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AtomAnimation extends Vue {
  @Prop({ type: String }) readonly triggerElementId?: string;

  @Prop({ type: String, default: 'left' }) readonly direction!: string;

  controller!: any;

  get classes() {
    const fadeClass = `fade-in--${this.direction}`;

    return {
      [fadeClass]: true,
      active: this.$breakpoint.mdAndDown,
      fade: true,
    };
  }

  mounted() {
    const images = this.$el.querySelectorAll('img');
    const imagesLoading: Promise<any>[] = [];

    for (const image of images) {
      imagesLoading.push(new Promise((resolve) => (image.onload = resolve)));
    }

    Promise.all(imagesLoading).then(() => this.init());

    window.addEventListener('resize', this.init);
  }

  updated() {
    this.init();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.init);
  }

  init() {
    if (this.controller) {
      this.controller.destroy();
      this.controller = null;
    }

    this.controller = new ScrollMagic.Controller();
    this.addAnimationScenes();
  }

  addAnimationScenes() {
    if (this.$breakpoint.mdAndDown) return;

    const toggleElement = this.$el as HTMLElement;
    new ScrollMagic.Scene({
      triggerElement: this.triggerElementId ? `#${this.triggerElementId}` : this.$el,
      duration: this.$el.clientHeight,
      offset: 300,
    })
      .setClassToggle(toggleElement, 'active')
      .on('leave', function (event: any) {
        if (event.scrollDirection === 'REVERSE') toggleElement.classList.add('active');
      })
      .addTo(this.controller);

    toggleElement.classList.add('active');
  }

  @Watch('$route')
  onChangeRoute() {
    this.init();
  }
}
