import { Model } from '@vuex-orm/core';
import Vue from 'vue';

export default class JobAdvertisement extends Model {
  static entity = 'jobAdvertisement';

  static fields() {
    return {
      image: this.string(''),
      title: this.string(''),
      location: this.string(''),
      content: this.string(''),
      isActive: this.boolean(false),
    };
  }

  static $api() {
    return {
      async index() {
        const url = `${Vue.prototype.$api.defaults.baseURL}/job-advertisements`;

        return await JobAdvertisement.api().get(url);
      },
    };
  }
}
