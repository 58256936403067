























// libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';

// types
import { BreakpointNameEnum } from '@/plugins/breakpoint/types';

// mixins
import ShadowMixin from '@/mixins/shadow-mixin';

@Component
export default class MoleculeTeaserPrimary extends Mixins(ShadowMixin) {
  @Prop({ type: Boolean, default: false }) readonly order!: boolean;

  @Prop({ type: Boolean, default: false }) readonly rounded!: boolean;

  @Prop({ type: Boolean, default: false }) readonly showDivider!: boolean;

  @Prop({ type: Boolean, default: true }) readonly center!: boolean;

  @Prop({ type: String, default: '' }) readonly text!: string;

  @Prop({ type: String, default: '' }) readonly title!: string;

  @Prop({ type: String, required: false }) readonly imageClass?: string;

  @Prop({ type: String, required: true }) readonly image!: string;

  @Prop({
    type: [Number, String],
    default: 4,
    validator: (value) => value >= 1 && value <= 12,
  })
  readonly imageSize!: number | string;

  get styles() {
    return {
      ...this.shadowMixinStyles,
    };
  }

  @Prop({
    type: String,
    default: BreakpointNameEnum.md,
    validator: (value) => {
      return [
        BreakpointNameEnum.xs,
        BreakpointNameEnum.sm,
        BreakpointNameEnum.md,
        BreakpointNameEnum.lg,
        BreakpointNameEnum.xl,
        BreakpointNameEnum.xxl,
      ].includes(value);
    },
  })
  readonly breakpoint!: BreakpointNameEnum;

  get imageClasses() {
    const breakpoint = `${this.breakpoint}AndDown`;

    return {
      'align-items-center': this.center,
      'col-12 p-4': true,
      'molecule-teaser-primary__image': true,
      'mb-5': !!(!this.order && (this.$breakpoint as any)[breakpoint]),
      'order-1': this.order,
      [`col-${this.breakpoint}-${this.imageSize}`]: true,
      [this.imageClass ?? '']: true,
    };
  }

  get contentClasses() {
    const breakpoint = `${this.breakpoint}AndDown`;

    const cols = 12 - parseInt(this.imageSize.toString(), 10);

    return {
      'align-items-center': this.center,
      'col-12': true,
      'd-flex ': true,
      'mb-5': !!(this.order && (this.$breakpoint as any)[breakpoint]),
      [`col-${this.breakpoint}-${cols}`]: true,
    };
  }

  get bodyClasses() {
    return {
      [`w-${this.breakpoint}-85`]: true,
      [this.order ? 'me-3' : 'ms-3']: true,
    };
  }
}
