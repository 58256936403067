










// libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';

// mixins
import TypoMixin from '@/mixins/typo-mixin';

// enums
enum TagEnum {
  div = 'div',
  p = 'p',
  span = 'span',
}

@Component
export default class AtomBody extends Mixins(TypoMixin) {
  @Prop({ type: String, default: '' }) readonly text!: string;

  @Prop({ type: String, default: '' }) readonly html!: string;

  @Prop({
    type: String,
    default: TagEnum.p,
    validator: (value) => [TagEnum.p, TagEnum.div, TagEnum.span].includes(value),
  })
  readonly tag!: TagEnum;

  get classes() {
    return {
      ...this.typoMixinClasses,
    };
  }
}
