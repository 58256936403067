// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ElevationMixin extends Vue {
  @Prop({
    type: [Number, String],
    default: '',
    validator: (value) => value >= 0 && value <= 24,
  })
  readonly elevation!: number | string;

  get elevationMixinClasses() {
    return {
      [`elevation-${this.elevation}`]: !!this.elevation,
    };
  }
}
