























// libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';
import { Route } from 'vue-router';

// mixins
import IconMixin, { IconSizeEnum, IconAnimationEnum } from '@/mixins/icon-mixin';

@Component
export default class MoleculeTeaserLink extends Mixins(IconMixin) {
  @Prop({ type: String, default: '' }) readonly imageActive!: string;

  @Prop({ type: String, default: 'arrow-right2' }) declare readonly icon: IconSizeEnum;

  @Prop({ type: String, default: IconAnimationEnum.right }) declare readonly iconAnimation: IconAnimationEnum;

  @Prop({ type: String, default: IconSizeEnum.lg }) declare readonly iconSize: IconSizeEnum;

  @Prop({ type: String, required: true }) readonly imageDefault!: string;

  @Prop({ type: String, required: true }) readonly text!: string;

  @Prop({
    type: Object as PropType<Route>,
    required: true,
    validator: (route: Route) => !!route.name,
  })
  readonly route!: Route;

  active = false;

  get classes() {
    return {
      ...this.iconMixinClasses,
      'd-flex': true,
      'justify-content-between': true,
      'molecule-teaser-link': true,
      'text-color--secondary': true,
      active: this.active,
    };
  }

  activate() {
    this.active = true;
  }

  disable() {
    this.active = false;
  }
}
