















































































// libraries
import { Component, Vue } from 'vue-property-decorator';
import LeaderLine, { AnchorAttachment } from 'leader-line-new';

// models
import Member from '@/models/Member';

// types
import { Routes } from '@/router/routes';

@Component
export default class TeamView extends Vue {
  careerRoute = Routes.Career;

  lines: Array<LeaderLine> = [];

  offsetWidth = 0;

  offsetHeight = 0;

  refreshLinesInterval: number | undefined;

  get members() {
    const members = Member.all();

    const animal = new Member({
      image: require('@/assets/images/zoo.png'),
      name: 'Rita & Karl-Heinz',
      position: 'Unsere Patenkinder im Kölner Zoo',
    });

    const conclusion = new Member();

    members.push(animal);
    members.push(conclusion);

    return members;
  }

  async created() {
    this.refreshLinesInterval = setInterval(() => {
      const { offsetWidth, offsetHeight } = document.getElementById('app') ?? { offsetWidth: 0, offsetHeight: 0 };

      if (this.offsetWidth !== offsetWidth || this.offsetHeight !== offsetHeight) {
        this.offsetWidth = offsetWidth;
        this.offsetHeight = offsetHeight;
        this.removeLines();
        this.drawLines();
      }
    }, 100);
  }

  drawLines() {
    this.lines = [];

    let elements = document.getElementsByClassName('line-item');
    let previousElement: HTMLElement | null = null;
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i] as HTMLElement;
      if (previousElement === null) {
        previousElement = element;
        continue;
      }

      let previousOffset = this.getOffset(previousElement);
      let currentOffset = this.getOffset(element);
      let isNewRow = previousOffset.top !== currentOffset.top;

      let options: LeaderLine.Options = {};
      let startPoint: Element | AnchorAttachment = previousElement;
      let endPoint: Element | AnchorAttachment = element;
      if (isNewRow) {
        (options.startSocket = 'bottom'),
          (options.endSocket = 'left'),
          (options.startSocketGravity = [0, 200]),
          (options.endSocketGravity = [-700, -500]);
      } else {
        startPoint = LeaderLine.pointAnchor(previousElement, {
          x: previousElement.offsetWidth,
          y: this.getYOffsetForElement(previousElement, elements),
        });
        endPoint = LeaderLine.pointAnchor(element, { x: 0, y: this.getYOffsetForElement(element, elements) });
        options.path = 'straight';
      }
      let line = new LeaderLine(startPoint, endPoint, {
        color: 'black',
        size: 1,
        startPlug: 'behind',
        endPlug: 'behind',
      });
      line.setOptions(options);
      this.lines.push(line);
      previousElement = element;
    }
  }

  getYOffsetForElement(element: any, elements: any, minOffset = 0.6, maxOffset = 0.7) {
    let elementsArray = Array.prototype.slice.call(elements);
    let elementOffset = this.getOffset(element);
    let elementsInSameRow = elementsArray.filter((e: Element) => {
      let eOffset = this.getOffset(e);

      return eOffset.top === elementOffset.top;
    });
    let elementPositionInRow = 0;
    for (let i = 0; i < elementsInSameRow.length; i++) {
      let elementInRow = elementsInSameRow[i];
      let elementInRowOffset = this.getOffset(elementInRow);
      if (elementInRowOffset.left < elementOffset.left) {
        elementPositionInRow++;
      }
    }

    let offsetRange = maxOffset - minOffset;
    let offsetPerPosition = offsetRange / elementsInSameRow.length;
    let offsetForCurrentElement = offsetPerPosition * elementPositionInRow;

    return element.offsetHeight * (minOffset + offsetForCurrentElement);
  }

  groupElementsByRow(elements: any) {
    let rows: { [key: string]: Element[] } = {};

    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];
      let elementOffset = this.getOffset(element);
      let top = Math.trunc(elementOffset.top);
      rows[top] = [...(rows[top] ?? []), element];
    }

    return rows;
  }

  getOffset(el: Element) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  removeLines() {
    for (let i = 0; i < this.lines.length; i++) {
      try {
        this.lines[i].remove();
      } catch {
        console.log('removing line failed');
      }
    }
  }
}
