import { Model } from '@vuex-orm/core';
import Vue from 'vue';

export default class Stack extends Model {
  static entity = 'stack';

  static fields() {
    return {
      image: this.string(''),
      name: this.string(''),
    };
  }

  static $api() {
    return {
      async index() {
        const url = `${Vue.prototype.$api.defaults.baseURL}/stacks`;

        return await Stack.api().get(url);
      },
    };
  }
}
