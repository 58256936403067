








































// libraries
import { Component, Vue, Watch } from 'vue-property-decorator';
import ScrollMagic from 'scrollmagic';

// models
import JobAdvertisement from '@/models/JobAdvertisement';

// types
import { Routes } from '@/router/routes';

@Component
export default class CareerView extends Vue {
  contactRoute = Routes.Contact;

  controller!: any;

  mounted() {
    this.init();
  }

  updated() {
    this.init();
  }

  get items() {
    return JobAdvertisement.all();
  }

  init() {
    if (this.controller) {
      this.controller.destroy();
      this.controller = null;
    }

    this.controller = new ScrollMagic.Controller();

    this.initCards();
  }

  initCards() {
    if (!this.$refs.cardItemSection) return;

    const cards = (this.$refs.cardItemSection as Vue).$el.querySelectorAll('.fade');

    cards.forEach((card) => {
      card.classList.remove('active');

      if (this.$breakpoint.mdAndDown) {
        card.classList.add('active');

        return;
      }

      new ScrollMagic.Scene({ triggerElement: '#app', triggerHook: 0 })
        .setClassToggle(card, 'active')
        .addTo(this.controller);
    });
  }

  getTeaserOrder(index: number) {
    return index % 2 === 0;
  }

  getTeaserClasses(index: number) {
    const teaserOrder = this.getTeaserOrder(index);

    return {
      [teaserOrder ? 'fade-in--right' : 'fade-in--left']: true,
      fade: true,
    };
  }

  @Watch('$route')
  onChangeRoute() {
    this.init();
  }
}
