import { Model } from '@vuex-orm/core';
import Vue from 'vue';

export default class Contact extends Model {
  static entity = 'contact';

  static fields() {
    return {
      number: this.string(''),
    };
  }

  static $api() {
    return {
      async store(number: string) {
        const url = `${Vue.prototype.$api.defaults.baseURL}/contacts`;

        return await Contact.api().post(url, { number });
      },
    };
  }
}
