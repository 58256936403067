





























// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component
export default class MoleculeAvastarCard extends Vue {
  @Prop({ type: String, required: true }) readonly image!: string;

  @Prop({ type: String, default: '' }) readonly title!: string;

  @Prop({ type: String, default: '' }) readonly text!: string;

  @Prop({ type: String, default: '' }) readonly href!: string;

  @Prop({ type: String, default: '' }) readonly linkText!: string;

  @Prop({
    type: Object as PropType<{ [key: string]: string }>,
    default: () => ({}),
  })
  readonly route!: { [key: string]: string };

  isActive = false;

  mounted() {
    this.setActive(false);

    window.addEventListener('resize', () => this.setActive(false));
  }

  beforeDestroy() {
    window.removeEventListener('resize', () => this.setActive(false));
  }

  get isExternal() {
    return !!(this.href.length && Object.values(this.route).length === 0);
  }

  get isInternal() {
    return !!(this.href.length === 0 && Object.values(this.route).length);
  }

  get showLink() {
    return this.isExternal || this.isInternal;
  }

  get linkProps() {
    const options: { [key: string]: any } = {
      color: 'secondary',
      icon: 'arrow-right2',
      iconColor: 'primary',
      iconPosition: 'after',
      iconSize: 'lg',
      iconAnimation: 'right',
      text: true,
    };

    if (this.isInternal) {
      options.to = this.route;
    } else if (this.isExternal) {
      options.href = this.href;
    }

    return options;
  }

  setActive(status: boolean) {
    if (status) {
      if (!this.$breakpoint.mdAndUp) return;

      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
}
