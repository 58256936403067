

















// libraries
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HomeOutro extends Vue {
  image = require('@/assets/images/woman-phone.svg');
}
