// libraries
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

// types
import { Routes as Names } from './routes';

// plugins
import i18n from '@/plugins/i18n';

// layouts
import MainLayout from '@/components/layouts/MainLayout.vue';

// components
import CareerView from '@/views/CareerView.vue';
import ContactView from '@/views/contact/ContactView.vue';
import CredentialsView from '@/views/CredentialsView.vue';
import HomeView from '@/views/home/HomeView.vue';
import ServicesView from '@/views/services/ServicesView.vue';
import TeamView from '@/views/TeamView.vue';
import ErrorView from '@/views/ErrorView.vue';
import LawView from '@/views/LawView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: Names.Home,
        component: HomeView,
        meta: {
          heading: i18n.t('views.home.heading'),
          subheading: i18n.t('views.home.subheading'),
        },
      },
      {
        path: '/services',
        name: Names.Services,
        component: ServicesView,
        meta: {
          heading: i18n.t('views.services.heading'),
          subheading: i18n.t('views.services.subheading'),
        },
      },
      {
        path: '/credentials',
        name: Names.Credentials,
        component: CredentialsView,
        meta: {
          heading: i18n.t('views.credentials.heading'),
          subheading: i18n.t('views.credentials.subheading'),
        },
      },
      {
        path: '/team',
        name: Names.Team,
        component: TeamView,
        meta: {
          heading: i18n.t('views.team.heading'),
          subheading: i18n.t('views.team.subheading'),
        },
      },
      {
        path: '/career',
        name: Names.Career,
        component: CareerView,
        meta: {
          heading: i18n.t('views.career.heading'),
          subheading: i18n.t('views.career.subheading'),
        },
      },
      {
        path: '/contact',
        name: Names.Contact,
        component: ContactView,
        meta: {
          heading: i18n.t('views.contact.heading'),
          subheading: i18n.t('views.contact.subheading'),
        },
      },
    ],
  },
  {
    path: '/agb',
    name: Names.Agb,
    meta: {},
  },
  {
    path: '/legal',
    name: Names.Legal,
    component: LawView,
    meta: {},
  },
  {
    path: '*',
    name: Names.Error,
    component: ErrorView,
    meta: {},
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
