import { Model } from '@vuex-orm/core';
import Vue from 'vue';

export default class Review extends Model {
  static entity = 'review';

  static fields() {
    return {
      name: this.string(''),
      position: this.string(''),
      text: this.string(''),
    };
  }

  static $api() {
    return {
      async index() {
        const url = `${Vue.prototype.$api.defaults.baseURL}/reviews`;

        return await Review.api().get(url);
      },
    };
  }
}
